<!-- 景点详情页-->
<template>
  <div class="viliageDetail w-1400">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/xiangninghui' }"
          >乡宁荟</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/xiangninghui' }"
          >乡宁游</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/scenerySpotList' }"
          >景点</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ pageData.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="face mt-20">
      <el-row :gutter="40">
        <el-col :span="11">
          <div class="face-pic">
            <el-carousel
              trigger="click"
              height="420px"
              arrow="never"
            >
              <el-carousel-item
                v-for="item in pageData.photoUrls"
                :key="item + 'url'"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-col>
        <el-col :span="13">
          <div class="face-inco">
            <div class="face-price f-label"><b>等级：</b> {{ pageData.grade }}</div>
            <div class="face-person f-label">
             <b>门票价格：</b> <span style="color:red">￥</span>{{ pageData.price }}元
            </div>
            <div class="face-phone f-label">
             <b>开放时间：</b> {{ pageData.openingTime }}
            </div>
            <div class="face-phone f-label">
             <b>适合人群：</b> {{ pageData.suitablePeople }}
            </div>
            <div class="face-phone f-label">
             <b>适宜季节：</b> {{ pageData.bestTimes }}
            </div>
            <div class="face-phone f-label"><b>电话：</b>{{ pageData.telephone }}</div>
            <!-- <div class="face-phone f-label">
             <b>经度</b> ：{{ pageData.longitude }}
            </div>
             <div class="face-phone f-label">
             <b>纬度：</b> {{ pageData.latitude }}
            </div> -->
            <div class="face-phone f-label"><b>官网：</b>{{ pageData.website }}</div>
            <div class="face-phone f-label">
             <b>用时参考：</b> {{ pageData.usingTime }}
            </div>
            <div class="face-phone f-label"><b>地址：</b>{{ pageData.address }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="inc">
      <el-tabs type="border-card" @tab-click="tabChange">
        <el-tab-pane label="景区简介" class="inf">
          <div>
            {{pageData.introduction}}
          </div>
        </el-tab-pane>
        <el-tab-pane label="交通指导">
          <div>{{pageData.traffic}}</div>
          <div class="position" v-if="showMap">
              <arc-map :title="pageData.name" :center="[pageData.longitude, pageData.latitude]"></arc-map>
              <!-- <el-image style="width:100%;height:100%" :src="require('../../../../public/img/xnh/kayang-map.png')"></el-image>     -->
              <!-- <baidu-map
                class="map"
                :center="{ lng: pageData.longitude, lat: pageData.latitude }"
                :scroll-wheel-zoom="true"
                :zoom="14"
              >
                <bm-marker
                  :position="{
                    lng: pageData.longitude,
                    lat: pageData.latitude,
                  }"
                  :dragging="true"
                >
                  <bm-label
                    :content="pageData.name"
                    :labelStyle="{
                      color: 'skyblue',
                      fontSize: '16px',
                      border: '1px solid skyblue',
                      transform: 'translateX(-48%)',
                    }"
                    :offset="{ width: 0, height: 30 }"
                  />
                </bm-marker>
              </baidu-map> -->
            </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { GetScenerySpot } from "@/api/home.js";
export default {
  data() {
    return {
      pageData: {},
      showMap:false
    };
  },
  methods: {
    tabChange(a){
      if(a.label === '交通指导'){
        this.showMap = true
      }
    },
    getPageData() {
      GetScenerySpot(this.$route.query.id).then((res) => {
        if (res.data?.success == true) {
          const data = res.data.data;
          data.photoUrls = data.photoUrls.split(",");
          this.pageData = data;
        }
      });
    },
  },
  mounted() {
    this.getPageData();
  },
};
</script>
<style scoped>
.map {
  height: 500px;
}
.position {
  padding: 12px;
  width: 100%;
  height: 600px;
  overflow: hidden;
}
.inc{
  font-size: 18px;
  line-height: 50px;
}
.inf{
  text-indent: 40px;
}
.position {
  padding: 12px;
  width: 100%;
  height: 600px;
  overflow: hidden;
}
.path {
  padding-left: 30px;
}
.path p {
  height: 40px;
  line-height: 40px;
  color: #999;
  font-size: 16px;
}
.others .house-item {
  border: 1px solid #666;
  margin-top: 15px;
}
.house-price {
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #f9773b;
  text-align: right;
  padding-right: 12px;
}
.house-intro {
  font-size: 16px;
  line-height: 20px;
  text-indent: 24px;
  height: 60px;
  color: #777;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.house-h5 {
  font-size: 18px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
}
.house-info {
  padding: 10px;
}
.house-pic {
  width: 100%;
  height: 220px;
  overflow: hidden;
}
.others-title .more:hover {
  color: #1c91ff;
}
.others-title .h5 {
  font-size: 22px;
  color: #0169ca;
}
.others-title {
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #666;
}
.market2 span:nth-child(1) {
  border: 1px solid #f9773b;
  color: #f9773b;
}
.market2 span:nth-child(2) {
  border: 1px solid #1c91ff;
  color: #1c91ff;
}
.market2 span:nth-child(3) {
  border: 1px solid #33d448;
  color: #33d448;
}
.market2 span {
  padding: 0 8px;
  margin-right: 12px;
}
.score {
  color: #f9773b;
}
.market span {
  padding: 0 8px;
  background: #1c91ff;
  color: #fff;
  margin-left: 12px;
}
.item-info div.buy {
  color: #0169ca;
}
.item-info div {
  height: 29px;
  line-height: 29px;
  font-size: 16px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.advertising-main {
  padding: 0 10px;
  border: 1px solid #666;
}
.more:hover {
  color: #eee;
}
.more {
  cursor: pointer;
  font-size: 16px;
}
.advertising-h3 {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  background: #0169ca;
  padding: 0 6px;
}
.advertising-itme {
  margin-top: 12px;
  overflow: hidden;
  width: 100%;
  height: 120px;
}
.advertising-itme-pic {
  width: 170px;
  height: 114px;
}
.item-info {
  width: calc(100% - 180px);
}
.intro-text div {
  font-size: 16px;
  text-indent: 20px;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.intro-text h3 {
  height: 42px;
  line-height: 42px;
  font-size: 20px;
  font-weight: 600;
}
.intro-text {
  border: 1px solid #666;
  padding: 20px 10px;
}
.face-has li {
  background: #0169ca;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  margin-right: 30px;
}
.face-has ul {
  padding-left: 30px;
  width: 500px;
}
.face-has li {
  margin-top: 20px;
}
.face-intro,
.face-has {
  margin: 20px 0;
}
.face-intro li ~ li {
  margin-left: 30px;
}
.face-intro li {
  border: 1px solid #f9773b;
  padding: 10px 20px;
  font-size: 16px;
}
.viliageDetail {
  font-family: "微软雅黑";
}
.f-label {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}
.face-pic >>> .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.face-pic {
  height: 420px;
  width: 100%;
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>